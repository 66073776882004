<template>
  <!-- 标题组 -->
  <div class="paddbox autobox">
    <el-card>
      <!-- <marquee
          direction="right"
          style="color:red;font-size:18px;"
          ><i class="el-icon-message-solid"></i
          >背景为红色则说明视频无权限,需要删除视频并且重新拉取或者授权</marquee
        > -->
        <div class="marquee">
  <span>背景为红色则说明视频无权限,需要删除视频并且重新拉取或者授权</span>
</div>
      <el-container>
        <!-- 左侧容器 -->
        <el-aside>
          <div class="left-main">
            <div>
              <div>
                <el-button type="text" @click="addGrouptitleView()"
                  >添加标题组+</el-button
                >
              </div>
              <div class="flex">
                <el-input
                  placeholder="请输入内容"
                  v-model="searchTitleName"
                  clearable
                >
                </el-input>
                <el-button
                  icon="el-icon-search"
                  @click="selectGroupTitleFun()"
                ></el-button>
              </div>
            </div>
            <!-- 标题组列 -->
            <div class="nameDiv-y">
              <div
                :class="
                  groupTitleId == item.id
                    ? 'v-for-div-color v-for-div flex'
                    : 'v-for-div flex'
                "
                v-for="(item, index) in TitleGroupNameData"
                @click="selectGroupTitleSucaiFun(item.id)"
              >
                <div class="nameDiv flex">
                  <div>({{ item.count }})</div>
                  <div>{{ item.name }}</div>
                </div>
                <div class="title-tbn flex">
                  <div>
                    <el-button
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="deleteGroupTitleView(item, index)"
                    ></el-button>
                  </div>
                  <div>
                    <el-button
                      type="primary"
                      size="mini"
                      icon="el-icon-edit"
                      @click="updateGrouptitleNameFun(item)"
                    ></el-button>
                  </div>
                  <div>
                    <el-button
                      size="mini"
                      type="success"
                      icon="el-icon-share"
                      @click="shareDataViewFun(item)"
                    ></el-button>
                  </div>
                  <div>
                    <el-button
                      type="primary"
                      size="mini"
                      icon="el-icon-sort"
                      @click="CopyGrouptitleFun(item, index)"
                    ></el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-aside>
        <!-- 主要容器 -->
        <el-main>
          <div class="mainContainer">
            <div>
              <el-radio v-model="byteVersion" :label="1">字节版本1.0</el-radio>
              <el-radio v-model="byteVersion" :label="2">字节版本2.0</el-radio>
            </div>

            <div
              class="mainBtn flex"
              v-show="groupTitleId == '' ? false : true"
            >
              <!-- <div>
                <el-button
                  type="primary"
                  size="small"
                  @click="addtitleAndTextView()"
                  >{{
                    byteVersion == 1 ? '+新增视频与文案' : '新增视频'
                  }}</el-button
                >
              </div> -->
              <div>
                <div>
                  <el-button
                    type="success"
                    size="small"
                    @click="pullAccountVideoFun()"
                    >从账户拉取视频</el-button
                  >
                </div>
              </div>
            </div>

            <!-- 视频素材集合 -->
            <div class="videoListdiv flex">
              <div v-for="item in videoListData" class="v-for-videoList">
                <!-- <el-card> -->
                <el-card :style="{backgroundColor:item.imageId==null?'red':'white'}">
                  <div class="video-card">
                    <div class="imgv" @click="redirectUrlFun(item)">
                      <img :src="item.imageUrl" width="230" height="360" />
                    </div>
                    <div>{{ item.materialId }}</div>
                    <div>{{ item.text }}</div>
                    <div class="textBtn flex">
                      <div>
                        <el-button
                          type="danger"
                          size="mini"
                          plain
                          @click="deleteVideoFun(item)"
                          >删除</el-button
                        >
                      </div>
                      <div v-if="byteVersion == 1">
                        <el-button
                          type="primary"
                          size="mini"
                          plain
                          @click="redactText(item)"
                          >编辑</el-button
                        >
                      </div>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-card>

    <!-- 添加标题组弹窗控制 -->
    <el-dialog v-model="addGrouptitleViewValue">
      <div>
        <div>添加标题组名称</div>
        <el-divider></el-divider>
        <el-form label-width="140px" class="demo-ruleForm">
          <el-form-item label="标题组名称：" prop="name">
            <el-input v-model="addGroupName"></el-input>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div class="addTitleBtn flex">
          <div class="flex">
            <el-button type="warning" plain @click="addGrouptitleView()"
              >取消</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="insertGroupTitleFun()"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 编辑文案弹窗控制 -->
    <el-dialog v-model="redactTextValue">
      <div>
        <div>编辑文案</div>
        <el-divider></el-divider>
        <el-form label-width="140px" class="demo-ruleForm">
          <el-form-item label="文案：" prop="name">
            <el-input v-model="redactTextContent"></el-input>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div class="addTitleBtn flex">
          <div>
            <el-button type="warning" plain @click="redactTextView()"
              >取消</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="updateRedactTextFun()"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 复制视频组弹窗控制 -->
    <el-dialog v-model="copyGrouptitleViewValue">
      <div>
        <div>复制到新标题组</div>
        <el-divider></el-divider>
        <!-- 标题组列 -->
        <div class="nameDiv-y">
          <div
            :class="
              copyNewId == item.id
                ? 'v-for-div-color v-for-div flex'
                : 'v-for-div flex'
            "
            v-for="item in TitleGroupNameData2"
          >
            <div
              class="nameDiv flex"
              :disable="item.id == copyId"
              @click="checkNewGroupTitle(item)"
            >
              <div>{{ item.name }}</div>
              <div>({{ item.count }})</div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="addTitleBtn flex">
          <div>
            <el-button
              type="warning"
              plain
              @click="ChangeCopyGrouptitleViewValue()"
              >取消</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="copyGroupDateFun()"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 修改标题组名称弹窗控制 -->
    <el-dialog v-model="updateGrouptitleViewValue">
      <div>
        <div>修改标题组名称</div>
        <el-divider></el-divider>
        <el-form label-width="140px" class="demo-ruleForm">
          <el-form-item label="标题组名称：" prop="name">
            <el-input v-model="afterUpdateName"></el-input>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div class="addTitleBtn flex">
          <div>
            <el-button
              type="warning"
              plain
              @click="ChangeupdateGrouptitleViewValue()"
              >取消</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="updateGroupNameFun()"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 删除标题组弹窗控制 -->
    <el-dialog v-model="deleteGrouptitleViewValue" width="500px">
      <div>
        <div>确定删除标题组：《{{ deleteGrouptitleName }}》？</div>
        <!-- <el-divider></el-divider> -->
        <div class="addTitleBtn flex">
          <div>
            <el-button type="warning" plain @click="deleteGroupTitleView()"
              >取消</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="deleteGroupTitleFun()"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 新增视频与文案弹窗控制 -->
    <el-dialog v-model="addtitleAndTextViewValue" width="70%">
      <div>
        <div class="flex">
          <div>
            <el-button type="primary" @click="addData">新增一行数据</el-button>
          </div>
          <div class="tips">
            视频最大上传100M，由于视频文件较大上传请耐心等待~~~
          </div>
        </div>

        <div>
          <el-table
            :data="videoAndTextData"
            border
            style="width: 100%"
            v-loading="TableVideoLoading"
            element-loading-text="最多只能同时上传6个视频,请稍等"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <el-table-column label="id" width="120">
              <template #default="scope">
                <div>{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="视频" width="220">
              <!--                     action="https://wxappvue.df01.top/api/toutiaoGroupTitle/uploadVideo" -->
              <template #default="scope">
                <div
                  v-loading="scope.row.VideoLoading"
                  element-loading-text="视频上传中请稍等"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                  <!-- <div> -->
                  <el-upload
                    :disabled="scope.row.able"
                    class="upload-demo"
                    drag
                    action="https://wxappvue.df01.top/api/toutiaoGroupTitle/uploadVideo"
                    :headers="{ authorize: token }"
                    :show-file-list="false"
                    :on-progress="
                      (event, file, fileList) => {
                        uploadingFun(scope.$index, event, file, fileList)
                      }
                    "
                    :on-success="
                      (response, file) => {
                        successdataFun(scope.$index, response, file)
                      }
                    "
                    :on-error="
                      (err, file, fileList) => {
                        errordataFun(scope.$index, err, file, fileList)
                      }
                    "
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      width="200"
                      v-if="scope.row.imageUrl"
                      :src="scope.row.imageUrl"
                    />
                    <!-- style="width:120px;height:120px" -->
                    <!-- <video v-if="scope.row.videoUrl" width="120">
                      <source :src="scope.row.videoUrl" type="video/mp4" />
                    </video> -->

                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="文案" width="650" v-if="byteVersion == 1">
              <template #default="scope">
                <el-input
                  placeholder="请输入文案"
                  v-model="scope.row.text"
                  minlength="5"
                  maxlength="30"
                  clearable
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作">
              <template #default="scope">
                <div
                  class="tableBtn"
                  v-loading="scope.row.VideoLoading"
                  element-loading-text="视频上传中请稍等"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                  <div>
                    <el-button
                      type="danger"
                      @click="deleteTableData(scope.$index)"
                      >删除当前行</el-button
                    >
                  </div>
                  <div>
                    <el-button type="primary" @click="playVideo(scope.$index)"
                      >播放视频</el-button
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-divider></el-divider>
        <div class="addTitleBtn flex">
          <div>
            <el-button type="warning" plain @click="addtitleAndTextView()"
              >取消</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="submitVideoAndtext()"
              >提交</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 分享素材弹窗控制 -->
    <el-dialog v-model="shareDateView">
      <div>
        <el-card class="box-card">
          <div>
            <el-tabs v-model="methodType">
              <el-tab-pane label="根据部门查看" name="0">
                <div class="flex">
                  <div class="Ftree">
                    <el-tree
                      :data="ShareDeptDate"
                      @node-click="handleNodeClick"
                      default-expand-all="ture"
                    ></el-tree>
                    <!-- :props="defaultProps" -->
                  </div>
                  <div>
                    <div class="flex">
                      <div>
                        <el-input
                          placeholder="请输入投放姓名"
                          v-model="fuzzyName"
                          clearable
                        >
                        </el-input>
                      </div>
                      <div>
                        <el-button @click="selectAndDeptIdFun()" type="primary"
                          >查询</el-button
                        >
                      </div>
                    </div>
                    <div>
                      <el-table
                        ref="multipleTable"
                        :data="DeptTableDate"
                        tooltip-effect="dark"
                        style="width: 100%"
                        @selection-change="handleSelectionChange"
                      >
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column
                          prop="nickName"
                          label="姓名"
                          width="120"
                        >
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
                <div class="addTitleBtn flex">
                  <div>
                    <el-button @click="ChangeShareDateView()">取消</el-button>
                  </div>
                  <div>
                    <el-button type="primary" @click="confirmDeptFun()"
                      >确定</el-button
                    >
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="根据名称搜索" name="1">
                <div class="flex">
                  <div>
                    <el-input
                      placeholder="请输入投放姓名"
                      v-model="fuzzyName2"
                      clearable
                    >
                    </el-input>
                  </div>
                  <div>
                    <el-button @click="selectAndNickNameFun()" type="primary"
                      >查询</el-button
                    >
                  </div>
                </div>

                <div>
                  <el-table
                    ref="multipleTable"
                    :data="UserTableDate"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange2"
                  >
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="nickName" label="姓名" width="120">
                    </el-table-column>
                  </el-table>
                </div>
                <div class="addTitleBtn flex">
                  <div>
                    <el-button @click="ChangeShareDateView()">取消</el-button>
                  </div>
                  <div>
                    <el-button type="primary" @click="confirmDeptFun2()"
                      >确定</el-button
                    >
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </div>
    </el-dialog>

    <!-- 账户拉取视频弹窗控制 -->
    <el-dialog v-model="pullAccountVideoView">
      <div>
        <el-form label-width="80px">
          <el-form-item label="账户id：">
            <el-input
              type="number"
              v-model="accountId"
              placeholder="请输入账户id"
            ></el-input>
          </el-form-item>
          <el-form-item label="素材id：">
            <div>
              <el-input
                placeholder="请输入素材id，每行一个素材id，敲击回车换行"
                :rows="20"
                type="textarea"
                v-model="desc"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="downBtn flex">
        <div>
          <el-button @click="pullAccountVideoViewChange()">取消</el-button>
        </div>
        <div>
          <el-button @click="confirmFun()" type="primary">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 分页组件
import pagination from '@/components/pagination.vue'
import {
  selectGroupTitle,
  deleteGroupTitle,
  insertGroupTitle,
  selectGroupTitleSucai,
  deleteGroupTitleSucai,
  insertGroupTitleSucai,
  updateTitleSucai,
  selectAndDeptId,
  queryOrganization,
  shareTitleGroup,
  updateGroupTitleName,
  selectAndNickName,
  copyTitleGroup,
  syncTitleGroup,
  selectAccountIdIsNotExsit,
} from '@/request/new/videoBatchManagement'

import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch, defineComponent } from 'vue'
import { login } from '@/request'
import Image from '@/components/image.vue'
import { stringify } from 'qs'

export default {
  name: 'titleGroup',
  components: { pagination, Image },
  setup() {
    const state = reactive({
      // 账户id
      accountId: '',
      // 素材列表字符串
      desc: '',
      // 素材列表
      videoList: [],
      // 要复制的视频id
      copyId: '',
      // 复制到新增视频组id
      copyNewId: '',
      // 要修改的名称id
      afterUpdateNameId: '',
      // 要修改的名称
      afterUpdateName: '',
      // 选择类别
      methodType: '0',
      // 字节版本控制 1 1.0/ 2 2.0
      byteVersion: 2,
      // 视频上传加载
      TableVideoLoading: false,
      // 视频长传次数对象
      numberLoading: [],
      // 获取用户token
      token: 'Bearer' + localStorage.getItem('token'),
      // 搜索标题组的名称
      searchTitleName: '',
      // 标题组名称数据
      TitleGroupNameData: [],
      // 添加标题组的名称
      addGroupName: '',
      // 被选中的标题组
      groupTitleId: '',
      // 复制的标题组数据
      TitleGroupNameData2: [],
      // 修改标题组名称弹窗控制
      updateGrouptitleViewValue: false,
      // 添加标题组弹窗控制
      addGrouptitleViewValue: false,
      // 删除标题组弹窗控制
      deleteGrouptitleViewValue: false,
      // 要被删除的标题组名称
      deleteGrouptitleName: '',
      // 要被删除的标题组id
      deleteGrouptitleId: '',
      // 新增视频与文案弹窗控制
      addtitleAndTextViewValue: false,
      // 新增视频与文案弹窗控制
      redactTextValue: false,
      // 新增视频与文案数据
      videoAndTextData: [],
      // 视频素材数据
      videoListData: [],
      // 编辑的文案
      redactTextContent: '',
      // 编辑的id
      redactTextId: '',
      // 编辑文案的储存id
      rTextId: '',
      // 分享的素材组id
      shareId: '',
      // 分享的素材组弹窗的值
      shareDateView: false,
      // 复制的素材组弹窗的值
      copyGrouptitleViewValue: false,
      // 从账户拉取视频弹窗的值
      pullAccountVideoView: false,
      // 分享的组织数据
      ShareDeptDate: [],
      // 部门表格数据
      DeptTableDate: [],
      // 用户表格数据
      UserTableDate: [],
      // 被选中的部门
      checkDeptId: '',
      // 模糊查询的名称
      fuzzyName: '',
      // 根据名称查询
      fuzzyName2: '',
      // 被选中的账户人id
      multipleSelection: [],
      // 名字 被选中的账户人id
      multipleSelection2: [],
      // 条数
      pagesize: 20,
      // 当前页数
      currentPage: 1,
      // 总数
      total: 100,
      // 分页开始
      // 页数 发生改变
      handleCurrentChange: (e) => {
        state.currentPage = e
        seniorSelectAllFun()
      },
      // 个数 发生改变
      handleSizeChange: (e) => {
        state.pagesize = e
        seniorSelectAllFun()
      },
      // 分享素材弹窗控制值变化
      ChangeShareDateView: () => {
        state.shareDateView = !state.shareDateView
      },
      // 复制按钮
      CopyGrouptitleFun: (val, index) => {
        console.log('大幅度发', val)
        state.copyNewId = ''
        state.copyId = val.id
        const list = JSON.parse(JSON.stringify(state.TitleGroupNameData))

        list.splice(index, 1)
        state.TitleGroupNameData2 = list
        state.ChangeCopyGrouptitleViewValue()
      },
      // 选中新的视频组
      checkNewGroupTitle: (val) => {
        console.log('选中新的视频组', val)
        state.copyNewId = val.id
      },
      // 复制视频组的确定
      copyGroupDateFun: () => {
        if (state.copyId == '' || state.copyNewId == '') {
          return ElMessage({
            type: 'error',
            message: '请选择要复制的新视频组',
          })
        }
        const datas = {
          tid: state.copyId,
          newTid: state.copyNewId,
        }

        copyTitleGroup(datas).then((res) => {
          selectGroupTitleFun()
          state.ChangeCopyGrouptitleViewValue()
        })
      },
      // 复制素材弹窗控制值变化
      ChangeCopyGrouptitleViewValue: () => {
        state.copyGrouptitleViewValue = !state.copyGrouptitleViewValue
      },
      // 修改名称控制值变化
      ChangeupdateGrouptitleViewValue: () => {
        state.updateGrouptitleViewValue = !state.updateGrouptitleViewValue
      },
      // 修改名称按钮
      updateGrouptitleNameFun: (val) => {
        state.afterUpdateNameId = val.id
        state.afterUpdateName = val.name
        state.ChangeupdateGrouptitleViewValue()
      },
      // 修改的确定
      updateGroupNameFun: () => {
        if (state.afterUpdateName.length == 0) {
          return ElMessage({
            type: 'error',
            message: '标题组名称不能为空',
          })
        }
        const datas = {
          id: state.afterUpdateNameId,
          name: state.afterUpdateName,
        }

        updateGroupTitleName(datas).then((res) => {
          state.ChangeupdateGrouptitleViewValue()
          selectGroupTitleFun()
          ElMessage({
            type: 'success',
            message: '修改成功',
          })
        })
      },
      // 确定
      confirmDeptFun: () => {
        if (state.multipleSelection.length == 0) {
          return ElMessage({
            type: 'error',
            message: '投放人不能为空',
          })
        }
        const datas = {
          tid: state.shareId,
          uids: state.multipleSelection,
        }

        console.log('12323', state.multipleSelection)

        console.log('12323', datas)
        shareTitleGroup(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '分享成功',
          })
        })
        state.ChangeShareDateView()
      },
      // 点击分享
      shareDataViewFun: (data) => {
        state.shareId = data.id
        state.fuzzyName = ''
        state.fuzzyName2 = ''
        state.ChangeShareDateView()
        state.queryOrganizationFun()
      },
      // 查询当前用户所在组织 最上级别
      queryOrganizationFun: () => {
        queryOrganization().then((res) => {
          state.ShareDeptDate = res.data
          state.checkDeptId = state.ShareDeptDate[0].id
          state.selectAndDeptIdFun()
        })
      },
      // 根据姓名查询
      selectAndNickNameFun: () => {
        if (state.fuzzyName2.length == 0) {
          return ElMessage({
            type: 'error',
            message: '姓名不能为空',
          })
        }
        const datas = { nickName: state.fuzzyName2 }

        selectAndNickName(datas).then((res) => {
          state.UserTableDate = res.data
        })
      },
      // 视频与文案新增一行
      addData: () => {
        if (state.videoAndTextData.length < 10) {
          state.videoAndTextData.push({
            videoSignature: '',
            videoUrl: '',
            videoUrl2: '',
            text: '',
            able: false,
            VideoLoading: false,
          })
        } else {
          return ElMessage({
            type: 'error',
            message: '最多只能10条数据!!!',
          })
        }
      },
      // 新增数据的表格数据上传完成视频回调
      successdataFun(val, response, file, fileList) {
        state.numberLoading.forEach((item, index) => {
          if (item == val) {
            state.numberLoading.splice(index, 1)
          }
        })

        if (state.numberLoading.length < 6) {
          state.TableVideoLoading = false
        }
        if (response.code == 500) {
          ElMessage({
            type: 'error',
            message: response.msg,
          })
          state.videoAndTextData[val].VideoLoading = false
        } else {
          state.videoAndTextData[val].videoUrl = response.data.videoUrl
          state.videoAndTextData[val].videoUrl2 = response.data.videoUrl2
          state.videoAndTextData[val].videoSignature =
            response.data.videoSignature
          state.videoAndTextData[val].imageId = response.data.imageId
          state.videoAndTextData[val].imageUrl = response.data.image_url
          state.videoAndTextData[val].videoId = response.data.video_id
          state.videoAndTextData[val].able = true
        }
        state.videoAndTextData[val].VideoLoading = false
        console.log('已经关闭第' + (val + 1) + '个加载中')
      },
      // 上传视频回调失败
      errordataFun(indexVal, err, file, fileList) {
        console.log('错误err', err)
        console.log('错误file', file)
        console.log('错误fileList', fileList)
        ElMessage({
          type: 'error',
          message: '上传视频回调失败!',
        })
        state.numberLoading.forEach((item, index) => {
          if (item == indexVal) {
            state.numberLoading.splice(index, 1)
          }
        })
        state.videoAndTextData[indexVal].VideoLoading = false
        state.TableVideoLoading = false
      },
      // 视频上传时
      uploadingFun: (val, event, file, fileList) => {
        state.videoAndTextData[val].VideoLoading = true
        if (state.numberLoading.length == 6) {
          state.TableVideoLoading = true
        }

        if (state.numberLoading.indexOf(val) == -1) {
          state.numberLoading.push(val)
        }
      },
      // 视频与文案的表格图片数据上传前校验
      beforeAvatarUpload(file) {
        if (
          [
            'video/mp4',
            'video/ogg',
            'video/flv',
            'video/avi',
            'video/wmv',
            'video/rmvb',
            'video/mov',
          ].indexOf(file.type) === -1
        ) {
          ElMessage({
            type: 'error',
            message: '请上传正确的视频格式',
          })
          return false
        }
        return true
      },
      // 视频与文案数据删除
      deleteTableData: (val) => {
        state.videoAndTextData.splice(val, 1)
      },
      // 播放视频
      playVideo: (val) => {
        state.videoAndTextData[val].videoUrl2 == ''
          ? ElMessage({
              type: 'error',
              message: '请先上传视频',
            })
          : window.open(state.videoAndTextData[val].videoUrl2, '_blank')
      },
      // 提交视频和文案
      submitVideoAndtext: () => {
        let msg = ''
        let submitValue = true

        if (state.videoAndTextData.length > 10) {
          return ElMessage({
            type: 'error',
            message: '最多只能提交10行数据！',
          })
        }

        if (state.byteVersion == 1) {
          state.videoAndTextData.forEach((item) => {
            if (item.videoUrl2 == '' || item.text == '') {
              submitValue = false
              msg = '请上传视频和文案填写完整'
            }
            if (item.text.length < 5 || item.text.length > 30) {
              submitValue = false
              msg = '文案必须在5~30字'
            }
          })
        } else {
          state.videoAndTextData.forEach((item) => {
            if (item.videoUrl2 == '') {
              submitValue = false
              msg = '请上传视频完整'
            }
          })
        }

        console.log('文案填写完整：', state.videoAndTextData)

        if (submitValue == false) {
          ElMessage({
            type: 'error',
            message: msg,
          })
        } else {
          const datas = {
            tid: state.groupTitleId,
            data: state.videoAndTextData,
            // data: JSON.stringify(state.videoAndTextData),
          }

          insertGroupTitleSucai(datas).then((res) => {
            state.videoAndTextData = []
            state.addtitleAndTextView()
            ElMessage({
              type: 'success',
              message: '提交完成',
            })
            state.selectGroupTitleSucaiFun(state.groupTitleId)
            selectGroupTitleFun()
          })
        }
      },
      // 节点点击时被回调
      handleNodeClick(a, b, c) {
        console.log('a', a)
        console.log('b', b)
        console.log('c', c)
        state.checkDeptId = a.id
        state.selectAndDeptIdFun()
      },
      // 根据部门查用户
      selectAndDeptIdFun: () => {
        const datas = {
          deptId: state.checkDeptId,
          nickName: state.fuzzyName,
        }

        selectAndDeptId(datas).then((res) => {
          state.DeptTableDate = res.data
        })
      },
      // 账户拉取视频弹窗值的变化
      pullAccountVideoViewChange: () => {
        state.pullAccountVideoView = !state.pullAccountVideoView
      },
      // 从账户拉取视频
      pullAccountVideoFun: () => {
        state.pullAccountVideoViewChange()
        state.accountId = ''
        state.desc = ''
        state.videoList = []
      },
      // 从账户拉取视频的确定
      confirmFun: () => {
        if (state.accountId.length == 0) {
          return ElMessage({
            type: 'error',
            message: '请填写账户id',
          })
        }

        const datas = {
          advertiserId: Number(state.accountId),
        }
        console.log('state.accountId', state.accountId)
        selectAccountIdIsNotExsit(datas).then((res) => {
          if (res.code == 200) {
            if (state.desc.length == 0) {
              return ElMessage({
                type: 'error',
                message: '请填写素材id',
              })
            }
            state.videoList = state.desc.split(/[(\r\n)\r\n]+/)
            state.videoList.forEach((item, index) => {
              if (!item) {
                state.videoList.splice(index, 1)
              }
            })
            console.log('state.videoList', state.videoList)
            const datass = {
              tid: state.groupTitleId,
              advertiserId: state.accountId,
              materialIds: state.videoList,
            }
            syncTitleGroup(datass).then((res) => {
              state.selectGroupTitleSucaiFun(state.groupTitleId)
              state.pullAccountVideoViewChange()
              selectGroupTitleFun('删除')
            })
          }
        })
      },
      // 用户搜索的确定
      confirmDeptFun2: () => {
        if (state.UserTableDate.length == 0) {
          return ElMessage({
            type: 'error',
            message: '请选择投放人',
          })
        }
        const datas = {
          tid: state.shareId,
          uids: state.multipleSelection2,
        }

        shareTitleGroup(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '分享成功',
          })
        })
        state.ChangeShareDateView()
      },
      // 表格选框
      handleSelectionChange: (val) => {
        state.multipleSelection = []
        val.forEach((item) => {
          state.multipleSelection.push(item.id)
        })
      },
      // 表格选框
      handleSelectionChange2: (val) => {
        state.multipleSelection2 = []
        val.forEach((item) => {
          state.multipleSelection2.push(item.id)
        })
      },
      // 新增视频和文案弹窗控制
      addtitleAndTextView: () => {
        state.addtitleAndTextViewValue
          ? (state.addtitleAndTextViewValue = false)
          : (state.addtitleAndTextViewValue = true)
      },
      // 编辑文案弹窗控制
      redactTextView: () => {
        state.redactTextValue
          ? (state.redactTextValue = false)
          : (state.redactTextValue = true)
      },
      // 打开编辑文案弹窗
      redactText: (item) => {
        state.redactTextContent = item.text
        state.redactTextId = item.id
        state.redactTextView()
      },
      // 编辑文案的确定
      updateRedactTextFun: () => {
        if (
          state.redactTextContent.length < 5 ||
          state.redactTextContent.length > 30
        ) {
          ElMessage({
            type: 'error',
            message: '文案必须在5~30字',
          })
          return
        }

        const datas = {
          id: state.redactTextId,
          text: state.redactTextContent,
        }

        updateTitleSucai(datas).then((res) => {
          if (state.groupTitleId.length != 0) {
            state.rTextId = state.groupTitleId
          }
          state.groupTitleId = state.rTextId
          ElMessage({
            type: 'success',
            message: '编辑成功',
          })
          state.redactTextView()
          state.redactTextId = ''
          state.redactTextContent = ''
          selectGroupTitleFun()
          state.selectGroupTitleSucaiFun(state.groupTitleId)
        })
      },
      // 删除标题组弹窗
      deleteGroupTitleView: (data, index) => {
        if (data != undefined) {
          state.deleteGrouptitleName = data.name
          state.deleteGrouptitleId = data.id
        }
        // state.deleteGrouptitleViewValue
        //   ? (state.deleteGrouptitleViewValue = false)
        //   : (state.deleteGrouptitleViewValue = true)
        state.deleteGroupTitleFun(index)
      },
      // 删除标题组
      deleteGroupTitleFun: (index) => {
        const datas = {
          id: state.deleteGrouptitleId,
        }

        deleteGroupTitle(datas).then((res) => {
          // state.deleteGroupTitleView()
          // selectGroupTitleFun()
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
          // location.reload()
          state.TitleGroupNameData.splice(index, 1)
          state.videoListData = []
        })
      },
      // 添加标题组弹窗
      addGrouptitleView: () => {
        state.addGrouptitleViewValue
          ? (state.addGrouptitleViewValue = false)
          : (state.addGrouptitleViewValue = true)
      },
      // 添加标题组
      insertGroupTitleFun: () => {
        if (state.addGroupName === '') {
          return ElMessage({
            type: 'error',
            message: '标题组名称不能为空',
          })
        }
        const datas = {
          name: state.addGroupName,
        }

        insertGroupTitle(datas).then((res) => {
          state.addGrouptitleView()
          ElMessage({
            type: 'success',
            message: '添加成功',
          })
          selectGroupTitleFun()
          state.addGroupName = ''
        })
      },
      // 通过标题组id查询素材
      selectGroupTitleSucaiFun: (item) => {
        state.groupTitleId = item
        const datas = {
          tid: item,
        }

        selectGroupTitleSucai(datas).then((res) => {
          state.videoListData = res.data
        })
      },
      // 删除素材
      deleteVideoFun: (item) => {
        const datas = {
          id: item.id,
        }

        deleteGroupTitleSucai(datas).then((res) => {
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
          selectGroupTitleFun('删除')
          state.selectGroupTitleSucaiFun(state.groupTitleId)
        })
      },

      // 跳转视频播放地址
      redirectUrlFun: (item) => {
        window.open(item.videoUrl, '_blank')
      },
    })

    // 搜索视频组名称
    const selectGroupTitleFun = (item) => {
      const datas = {}

      if (state.searchTitleName != '') {
        datas.name = state.searchTitleName
      }
      selectGroupTitle(datas).then((res) => {
        state.TitleGroupNameData = res.data
        if (item != '删除') {
          state.groupTitleId = ''
        }
        console.log('state.groupTitleId ', state.groupTitleId)
      })
    }

    selectGroupTitleFun()
    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
      selectGroupTitleFun,
    }
  },
}
</script>

<style lang="scss" scoped>
.el-main {
  --el-main-padding: 18px;
}

.el-aside {
  width: 26%;
}

.add {
  div {
    padding-right: 50px;
  }
}

.left-main {
  > div {
    margin-bottom: 10px;
  }
}

.nameDiv-y {
  // overflow-y: auto;
}

.addTitleBtn {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}

.mainBtn {
  margin-bottom: 10px;
  margin-top: 10px;
  > div {
    margin-right: 40px;
  }
}
.title-tbn {
  height: 40px;
  line-height: 40px;
  // width: 30%;
  > div {
    padding-right: 4px;
  }
}
.nameDiv {
  // border: 2px solid rgb(220, 223, 230);
  width: 72%;
  justify-content: space-between;
  margin-right: 10px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-for-div {
  align-content: center;
  border: 1px solid rgb(220, 223, 230);
  cursor: pointer;
  height: 40px;
  padding-left: 4px;
  box-sizing: border-box;
  border-radius: 1px;
  // > div {
  //   margin-top: 2px;
  // }
}

.v-for-div-color {
  background-color: rgb(102, 177, 255);
}

.v-for-div:hover {
  border: 1px solid rgb(102, 177, 255);
}
.tableBtn {
  > div {
    margin-bottom: 40%;
  }
}

.v-for-videoList {
  width: 270px;
  height: 480px;
  margin-right: 10px;
}

.videoListdiv {
  flex-wrap: wrap;
  overflow-y: auto;
}

.video-card {
  > div {
    margin-bottom: 10px;
  }
}

.imgv {
  cursor: pointer;
}

.avatar-uploader-icon {
  display: block;
  font-size: 180px;
}

.tips {
  margin-top: 10px;
  color: rgb(245, 8, 8);
}

.textBtn {
  justify-content: space-between;
}

.Ftree {
  margin-right: 30px;
}

.downBtn {
  justify-content: flex-end;
  padding: 10px 10px 0px 15px;
  > div {
    padding-left: 30px;
  }
}

.marquee {
  white-space: nowrap; /* 禁止文本换行 */
  overflow: hidden;    /* 隐藏超出容器的内容 */
  box-sizing: border-box; /* 确保宽度包含 padding 和 border */
  width: 90%;        /* 容器宽度占满父元素 */
}

.marquee span {
  display: inline-block; /* 将 span 设置为行内块元素，使其可以水平滚动 */
  padding-right: 100%;    /* 在左侧添加 100% 的 padding，使内容从容器右侧开始 */
  animation: marquee 18s linear infinite; /* 应用动画 */
  font-size: 18px;      /* 字体大小 */
  color: red;           /* 字体颜色 */
}

@keyframes marquee {
  0% { transform: translateX(0%); } /* 动画开始时，内容在容器左侧 */
  100% { transform: translateX(100%); } /* 动画结束时，内容滚动到容器右侧 */
}

//
</style>
